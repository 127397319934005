import { colors } from '@material-ui/core'

const white = '#FFFFFF'

export const housi = {
  darkPink: '#E52D5E',
  pink: '#FA3C6E',
  lightPink: '#F785A3',
  blue: '#000050',
  darkBlue: '#000030',
  lightBlue: '#283593',
  darkGreen: '#43A047',
  green: '#4CAF50',
  lightGreen: '#EFF8F0',
}

export default {
  primary: {
    contrastText: white,
    dark: housi.darkBlue,
    main: housi.blue,
    light: housi.lightBlue,
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: housi.pink,
    light: colors.blue.A400,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  success: {
    contrastText: white,
    dark: housi.darkGreen,
    main: housi.green,
    light: housi.lightGreen,
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  divider: colors.grey[200],
}
