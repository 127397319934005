export const asyncLocalStorage = {
  setItem: async (key: string, value: string) => {
    await Promise.resolve()
    localStorage.setItem(key, value)
  },
  getItem: async (key: string) => {
    await Promise.resolve()
    return localStorage.getItem(key)
  },
  clear: async () => {
    await Promise.resolve()
    return localStorage.clear()
  },
}
