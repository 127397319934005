import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/main.scss'

import { ITheme, lightTheme, RetrieverProvider } from '@retriever-ui/react'
import axios from 'axios'
import React from 'react'
import { renderRoutes } from 'react-router-config'
import { RecoilRoot } from 'recoil'
import interceptors from 'src/interceptors'

import ScrollReset from './components/ScrollReset'
import StylesProvider from './components/StylesProvider'
import { DrawerContextProvider } from './context/DrawerContext'
import { MessageSnackbarContext } from './providers/MessageSnackbarProvider'
import routes from './routes'

function App() {
  const notify = React.useContext(MessageSnackbarContext)

  React.useEffect(() => {
    interceptors.forEach((interceptor) =>
      axios.interceptors.response.use(
        (response) => response,
        interceptor(notify)
      )
    )
  }, [])

  return (
    <RecoilRoot>
      <RetrieverProvider
        theme={
          {
            ...lightTheme,
            colors: {
              ...lightTheme.colors,
              'primary.50': '#D9D9E5',
              'primary.100': '#B3B3CB',
              'primary.200': '#666696',
              'primary.300': '#333373',
              'primary.400': '#000050',
              'primary.500': '#000040',
              'primary.600': '#000030',
              'primary.700': '#000020',
              'secondary.100': '#FEC5D4',
              'secondary.200': '#FC8AA8',
              'secondary.300': '#FB638B',
              'secondary.400': '#FA3C6E',
              'secondary.500': '#E13663',
              'secondary.600': '#AF2A4D',
              'secondary.700': '#7D1E37',
            },
            font: {
              ...lightTheme.font,
              family: 'Roboto',
            },
          } as ITheme
        }
      >
        <StylesProvider>
          <DrawerContextProvider>
            <ScrollReset />
            {renderRoutes(routes)}
          </DrawerContextProvider>{' '}
        </StylesProvider>
      </RetrieverProvider>
    </RecoilRoot>
  )
}

export default App
