import { ThemeProvider } from '@material-ui/styles'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import axios from 'axios'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { SWRConfig } from 'swr'

import App from './App'
import ErrorComponent from './layouts/Error'
import MessageSnackbarProvider from './providers/MessageSnackbarProvider'
import { theme } from './theme'

const history = createBrowserHistory()
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),

    new CaptureConsoleIntegration(),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENVIROMENT,
})

axios.defaults.baseURL = process.env.REACT_APP_BACKOFFICE_URL
axios.defaults.headers = {
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

const fetcher = (url: string) =>
  axios.get(url).then((response) => response.data)

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
    <Router history={history}>
      <SWRConfig value={{ fetcher }}>
        <ThemeProvider theme={theme}>
          <MessageSnackbarProvider>
            <RecoilRoot>
              <App />
            </RecoilRoot>
          </MessageSnackbarProvider>
        </ThemeProvider>
      </SWRConfig>
    </Router>
  </Sentry.ErrorBoundary>,

  document.getElementById('root')
)
