import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
  },
  center: {
    margin: 'auto',
    maxWidth: 700,
  },
  error: {
    maxWidth: 1200,
  },
  title: {
    maxWidth: 700,
  },
}))

function ErrorComponent(): React.ReactElement | null {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.center}>
        <div className={classes.title}>
          <Typography variant="h2">
            Houve um erro ao acessar o marketplace housi
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default ErrorComponent
