const createPermissionCrud = (resourceName: string) => ({
  [`CREATE_${resourceName.toUpperCase()}`]: `create_${resourceName}`,
  [`LIST_${resourceName.toUpperCase()}`]: `list_${resourceName}`,
  [`UPDATE_${resourceName.toUpperCase()}`]: `update_${resourceName}`,
  [`DELETE_${resourceName.toUpperCase()}`]: `delete_${resourceName}`,
})

export const permissions = {
  ...createPermissionCrud('customers'),
  ...createPermissionCrud('reservations'),
  ...createPermissionCrud('articles'),
  ...createPermissionCrud('roles'),
  ...createPermissionCrud('permissions'),
  ...createPermissionCrud('users'),
  ...createPermissionCrud('stories'),
  ...createPermissionCrud('properties'),
  ...createPermissionCrud('notifications'),
  ...createPermissionCrud('rewards_campaigns'),
  ...createPermissionCrud('rosie_categories'),
  ...createPermissionCrud('rosie_extras'),
  ...createPermissionCrud('rosie_services'),
  ...createPermissionCrud('rosie_schedule'),
  ...createPermissionCrud('rosie_schedule'),
  ...createPermissionCrud('rosie_plugs_on_housi'),
  ...createPermissionCrud('waiting_lists_housis'),
  ...createPermissionCrud('waiting_lists_new_housis'),
  ...createPermissionCrud('waiting_lists_cities'),
}
export const getPermissionCrud = (resourceName: string) => [
  permissions[`CREATE_${resourceName.toUpperCase()}`],
  permissions[`UPDATE_${resourceName.toUpperCase()}`],
  permissions[`DELETE_${resourceName.toUpperCase()}`],
  permissions[`LIST_${resourceName.toUpperCase()}`],
]

export const hasPermission = (permissionValue: string) => {
  if (typeof permissionValue === 'undefined') return true
  const storedRoles = localStorage.getItem('roles')
  if (!storedRoles) return false

  const roles = JSON.parse(storedRoles)

  for (const role of roles) {
    if (role.slug === 'administrator') return true
    for (const permission of role.permissions) {
      if (permissionValue === permission) return true
    }
  }

  return false
}

export const hasPermissions = (permissions: string[]) => {
  if (typeof permissions === 'undefined') return true
  const storedRoles = localStorage.getItem('roles')
  if (!storedRoles) return false

  const roles = JSON.parse(storedRoles)

  for (const role of roles) {
    if (role.slug === 'administrator') return true
    for (const permission of role.permissions) {
      if (permissions.some((slug) => slug === permission.slug)) return true
    }
  }

  return false
}
