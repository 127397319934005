import { AppBar, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
}))

function Topbar({ className, ...rest }) {
  const classes = useStyles()

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="https://housi-publico.s3-sa-east-1.amazonaws.com/growth-contracts/Logo.svg"
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
}

export default Topbar
