import { colors, Snackbar, SnackbarContent } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/Error'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: (props) => props.color,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}))

const snackColors = {
  success: colors.green[700],
  error: colors.red[700],
  info: colors.blue[700],
}

function MessageSnackbar({ open, onClose, message, type }) {
  const classes = useStyles({ color: snackColors[type] })

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={2000}
      onClose={onClose}
      open={open}
    >
      <SnackbarContent
        className={classes.content}
        message={
          <span className={classes.message}>
            {type === 'error' && <ErrorIcon className={classes.icon} />}
            {type === 'success' && <CheckIcon className={classes.icon} />}
            {message}
          </span>
        }
        variant="h6"
      />
    </Snackbar>
  )
}

MessageSnackbar.propTypes = {
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  message: PropTypes.string,
}

MessageSnackbar.defaultProps = {
  open: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  message: '',
}

export default MessageSnackbar
