import { createMuiTheme } from '@material-ui/core'

import overrides from './overrides'
import palette from './palette'
import typography from './typography'

const baseTheme = {
  palette,
  typography,
  overrides,
}

export const theme = createMuiTheme(baseTheme)
export const themeWithRtl = createMuiTheme({ ...baseTheme, direction: 'rtl' })
