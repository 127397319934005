import { jssPreset, StylesProvider } from '@material-ui/core/styles'
import { create } from 'jss'
import PropTypes from 'prop-types'
import React from 'react'

const jss = create({ plugins: [...jssPreset().plugins] })

function CustomStylesProvider({ children }) {
  return (
    <StylesProvider jss={jss}>
      <div>{children}</div>
    </StylesProvider>
  )
}

CustomStylesProvider.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
}

export default CustomStylesProvider
