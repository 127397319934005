/* eslint-disable react/no-multi-comp */
import {
  ClickAwayListener,
  colors,
  Divider,
  Drawer,
  List,
  ListSubheader,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router'
import NavItem from 'src/components/NavItem'
import { DrawerContext } from 'src/context/DrawerContext'
import { hasPermissions } from 'src/roles'

import navConfig from './navConfig'

const drawerWidth = 230
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  navigation: {
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200],
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items,
  subheader,
  ...rest
}) {
  const { drawerOpen } = React.createContext(DrawerContext)
  return (
    <List>
      {drawerOpen && subheader && (
        <ListSubheader disableSticky>{subheader}</ListSubheader>
      )}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item, index) =>
          reduceChildRoutes({
            acc,
            item,
            index,
            ...rest,
          }),
        []
      )}
    </List>
  )
}

function reduceChildRoutes({ acc, pathname, item, index, depth = 0 }) {
  if (hasPermissions(item.permissions)) {
    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false,
      })

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          label={item.label}
          title={item.title}
          open={Boolean(open)}
          transitionDelay={index * 50}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
          })}
        </NavItem>
      )
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          transitionDelay={index * 50}
          icon={item.icon}
          key={item.href}
          label={item.label}
          title={item.title}
        />
      )
    }
  }

  return acc
}

function NavBar({ openMobile, onMobileClose, className, ...rest }) {
  const classes = useStyles()
  const location = useLocation()

  const {
    drawerOpen: open,
    handleDrawerClose,
    handleDrawerOpen,
  } = React.useContext(DrawerContext)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {navConfig.map((list) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
          })
        )}
      </nav>
      <Divider className={classes.divider} />
    </div>
  )

  const onMouseOut = () => {
    handleDrawerClose()
  }

  const onMouseOver = () => {
    handleDrawerOpen()
  }
  return (
    <>
      <Drawer
        anchor="left"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
        PaperProps={{
          onMouseOver,
        }}
        variant="permanent"
      >
        <ClickAwayListener onClickAway={onMouseOut}>
          {content}
        </ClickAwayListener>
      </Drawer>
    </>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
