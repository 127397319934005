import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { endpoints } from 'src/endpoints'

const authAxios = axios.create()
authAxios.defaults.baseURL = process.env.REACT_APP_BACKOFFICE_URL

export const login = async (email: string, password: string) => {
  const { url } = endpoints.LOGIN()
  return trackPromise(authAxios.post(url, { email, password }), 'login')
}

export const logout = async () => {
  const { url } = endpoints.LOGOUT()
  return trackPromise(authAxios.delete(url), 'logout')
}

export const refresh = async (refreshToken: string) => {
  const { url } = endpoints.REFRESH()
  return trackPromise(authAxios.post(url, { refreshToken }), 'refresh')
}
