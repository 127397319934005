/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import AuthLayout from './layouts/Auth'
import DashboardLayout from './layouts/Dashboard'

interface IRouteObject {
  path?: string
  exact?: boolean
  component: (props: any) => JSX.Element | null
  routes?: IRouteObject[]
  route?: string
}

const LoginComponent = lazy(() => import('src/pages/Login'))
const HomeComponent = lazy(() => import('src/pages/Home'))
const ForgetComponent = lazy(() => import('src/pages/ForgetPassword/Form'))

const routes: IRouteObject[] = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: LoginComponent,
      },
      {
        path: '/auth/forget',
        exact: true,
        component: ForgetComponent,
      },
      {
        component: () => <Redirect to="/auth/login" />,
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/home',
        exact: true,
        component: HomeComponent,
      },
      {
        path: '/reset',
        exact: true,
        component: lazy(
          () => import('src/pages/CreatePassword/Form/CreatePasswordForm')
        ),
      },
      {
        path: '/properties',
        exact: true,
        component: lazy(() => import('src/pages/Properties')),
      },
      {
        path: '/properties/new',
        exact: true,
        component: lazy(() => import('src/pages/Properties/Form')),
      },
      {
        path: '/properties/edit/:id',
        exact: true,
        component: lazy(() => import('src/pages/Properties/Form')),
      },
      {
        path: '/properties/allocations/:id',
        exact: true,
        component: lazy(() => import('src/pages/Calendar')),
      },
      {
        path: '/properties/edit/:id',
        exact: true,
        component: lazy(() => import('src/pages/Properties/Form')),
      },
      {
        path: '/buildings',
        exact: true,
        component: lazy(() => import('src/pages/Building')),
      },
      {
        path: '/buildings/edit/:id',
        exact: true,
        component: lazy(() => import('src/pages/Building/Form/BuildingForm')),
      },
      {
        path: '/buildings/new',
        exact: true,
        component: lazy(() => import('src/pages/Building/Form/BuildingForm')),
      },
      {
        path: '/prices',
        exact: true,
        component: lazy(() => import('src/pages/Prices')),
      },
      {
        path: '/prices/new',
        exact: true,
        component: lazy(() => import('src/pages/Prices/Form/PriceForm')),
      },
      {
        path: '/test/prices/short-stay/new',
        exact: true,
        component: lazy(
          () => import('src/pages/Prices/ShortStayForm/CreateShortStay')
        ),
      },
      {
        path: '/test/prices/short-stay/edit/:id',
        exact: true,
        component: lazy(
          () => import('src/pages/Prices/ShortStayForm/EditShortStay')
        ),
      },
      {
        path: '/prices/edit/:id',
        exact: true,
        component: lazy(() => import('src/pages/Prices/Form/PriceForm')),
      },
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('src/pages/Profile')),
      },
      {
        path: '/reservations',
        exact: true,
        component: lazy(() => import('src/pages/Reservations')),
      },
      {
        path: '/reservations/:id',
        exact: true,
        component: lazy(() => import('src/pages/Reservations/Details')),
      },
      {
        path: '/notifications',
        exact: true,
        component: lazy(() => import('src/pages/Notification')),
      },
    ],
  },
]
export default routes
