import React from 'react'
import MessageSnackbar from 'src/components/MessageSnackbar'

export const MessageSnackbarContext = React.createContext()

const MessageSnackbarProvider = ({ children }) => {
  const [openSnackbar, setOpenSnackbar] = React.useState({
    open: false,
    message: '',
  })

  const notify = React.useCallback((message, type) => {
    setOpenSnackbar({ open: true, type, message })
  }, [])

  const handleSnackbarClose = React.useCallback(() => {
    setOpenSnackbar({ open: false, message: openSnackbar.msg })
  }, [openSnackbar])

  return (
    <MessageSnackbarContext.Provider value={notify}>
      {children}
      <MessageSnackbar
        type={openSnackbar.type}
        message={openSnackbar.message}
        onClose={handleSnackbarClose}
        open={openSnackbar.open}
      />
    </MessageSnackbarContext.Provider>
  )
}

export default MessageSnackbarProvider
