// import { hasPermission } from 'src/roles';

export const endpoints = {
  LOGIN: () => ({
    url: '/Auth/login',
  }),
  AUTH_RESET: () => ({
    url: '/Auth/reset',
  }),
  AUTH_FORGOT: () => ({
    url: '/Auth/forgot',
  }),
  LOGOUT: () => ({
    url: '/Auth',
  }),
  REFRESH: () => ({
    url: '/Auth/refresh-token',
  }),
  CREATE_PROPERTY: () => ({
    url: '/Properties',
  }),
  CREATE_PRICE: () => ({
    url: '/Prices',
  }),
  LIST_PRICE: (id: string) => ({
    url: `/Prices/${id}`,
  }),
  LIST_RESERVATIONS: () => ({
    url: '/reservations',
  }),
  LIST_PRICES: () => ({
    url: '/Prices',
  }),
  UPDATE_PRICE: (id: string) => ({
    url: `/Prices/${id}`,
  }),
  UPLOAD_PROPERTIES_IMAGES: () => ({
    url: '/Properties/images',
  }),
  UPLOAD_BUILDING_IMAGES: () => ({
    url: '/buildings/images',
  }),
  LIST_BUILDINGS: () => ({
    url: '/buildings',
  }),
  LIST_BUILDING: (id: string) => ({
    url: `/buildings/${id}`,
  }),
  CREATE_BUILDING: () => ({
    url: '/buildings',
  }),
  UPDATE_BUILDING: (id: string) => ({
    url: `/buildings/${id}`,
  }),
  LIST_PROPERTIES: () => ({
    url: '/properties',
  }),
  UPDATE_PROPERTY: (id: string) => ({
    url: `/properties/${id}`,
  }),
  LIST_PROPERTY: (id: string) => ({
    url: `/properties/${id}`,
  }),
  CREATE_PROPERTY_BULK: () => ({
    url: '/properties/bulk',
  }),
  UPDATE_PROPERTY_BULK: (id: string) => ({
    url: `/properties/${id}/bulk`,
  }),
  LIST_AMENITIES: () => ({
    url: '/Amenities',
  }),
  LIST_CITIES: () => ({
    url: '/cities',
  }),
  LIST_NEIGHTBORHOODS: (cityId: string) => ({
    url: `/cities/${cityId}/neighborhoods`,
  }),
  LIST_INVENTORIES: () => ({
    url: '/inventories',
  }),
  LIST_ALLOCATIONS: (propertyId: string) => ({
    url: `/properties/${propertyId}/allocations`,
  }),
  CREATE_ALLOCATION: (propertyId: string) => ({
    url: `/properties/${propertyId}/allocations`,
  }),
  DELETE_ALLOCATION: (propertyId: string, allocationId: string) => ({
    url: `/properties/${propertyId}/allocations/${allocationId}`,
  }),
  UPDATE_ICAL: (propertyId: string) => ({
    url: `/properties/${propertyId}/allocations/ical`,
  }),
  GET_PROFILE: () => ({
    url: '/profile',
  }),
  EDIT_PROFILE: () => ({
    url: '/profile',
  }),
  UPLOAD_AVATAR_PROFILE: () => ({
    url: '/profile/images',
  }),
  LIST_NOTIFICATIONS: () => ({
    url: '/notifications',
  }),
  UPDATE_NOTIFICATIONS: (id: string) => ({
    url: `/notifications/${id}`,
  }),
  CREATE_SHORT_STAY: () => ({
    url: `/prices/short-stays`,
  }),
  UPDATE_SHORT_STAY: (id: string) => ({
    url: `/prices/short-stays/${id}`,
  }),
  CREATE_CUSTOM_DATE: (shortstayId: string) => ({
    url: `/prices/short-stays/${shortstayId}/custom-dates`,
  }),
  UPDATE_CUSTOM_DATE: (shortstayId: string, customDate: string) => ({
    url: `/prices/short-stays/${shortstayId}/custom-dates/${customDate}`,
  }),
  UPDATE_CUSTOM_DATES: (shortstayId: string) => ({
    url: `/prices/short-stays/${shortstayId}/custom-dates/bulk`,
  }),
  CREATE_PROJECTION: (start: string, end: string) => ({
    url: `/prices/short-stays/projections?startDate=${start}&endDate=${end}`,
  }),
  NOTIFICATIONS_EMAIL: () => ({
    url: `/profile/config`,
  }),
}
