/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
// import React from 'react';
// import { permissions } from 'src/roles';
// import { colors } from '@material-ui/core';
// import BarChartIcon from '@material-ui/icons/BarChart';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import ChatIcon from '@material-ui/icons/ChatOutlined';
// import CodeIcon from '@material-ui/icons/Code';
// import DashboardIcon from '@material-ui/icons/DashboardOutlined';
// import ErrorIcon from '@material-ui/icons/ErrorOutline';
// import FolderIcon from '@material-ui/icons/FolderOutlined';
// import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
// import MailIcon from '@material-ui/icons/MailOutlined';
// import PresentToAllIcon from '@material-ui/icons/PresentToAll';
// import PersonIcon from '@material-ui/icons/PersonOutlined';
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
// import SettingsIcon from '@material-ui/icons/SettingsOutlined';
// import ViewConfigIcon from '@material-ui/icons/ViewComfy';
import ListIcon from '@material-ui/icons/List'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
// import Label from 'src/components/Label';
// import Label from 'src/components/Label';
// import { ReactComponent as ClockIcon } from 'src/assets/images/clock.svg';
// import { ReactComponent as LaptopIcon } from 'src/assets/images/laptop.svg';
import { ReactComponent as BarChartIcon } from 'src/assets/images/bar_chart.svg'
// import { ReactComponent as PersonIcon } from 'src/assets/images/person.svg';
// import { ReactComponent as SettingIcon } from 'src/assets/images/settings.svg';
import { ReactComponent as HousiPin } from 'src/assets/images/pin.svg'
// import LocalPlayIcon from '@material-ui/icons/LocalPlayOutlined';
// import { getPermissionCrud } from 'src/roles';

export default [
  {
    items: [
      {
        title: '',
        href: '/home',
        icon: HousiPin,
      },
      {
        title: 'Backoffice',
        href: '/customers',
        icon: BarChartIcon,
        items: [
          {
            title: 'Edificios',
            href: '/buildings',
          },
          {
            title: 'Anúncios',
            href: '/properties',
          },
          {
            title: 'Tarifas',
            href: '/prices',
          },
          {
            title: 'Reservas',
            href: '/reservations',
          },
        ],
      },
      {
        title: 'Perfis e Permissões',
        href: '',
        icon: PeopleIcon,
        items: [
          {
            title: 'Perfil do parceiro',
            href: '/profile',
          },
        ],
      },
      {
        title: 'Notificações',
        href: '/notifications',
        icon: ListIcon,
        items: [
          {
            title: 'Notificações Lidas',
            href: '/notifications?tab=SEEN',
          },
          {
            title: 'Todas as Notificações',
            href: '/notifications?tab=ALL_NOTIFICATIONS',
          },
        ],
      },
    ],
  },
]
