import axios, { AxiosResponse } from 'axios'
import { refresh } from 'src/pages/Login/loginActions'
import { asyncLocalStorage } from 'src/utils/asyncStorages'

export const UNAUTHORIZED_REDIRECT = (notify: any) => async (error: any) => {
  if (
    error.response.status === 401 &&
    window.location.pathname !== '/auth/login'
  ) {
    const redirectToLogin = (response: AxiosResponse<any>) =>
      asyncLocalStorage.clear().then(async () => {
        window.location.replace('/auth/login')
        notify('Sessão expirada', 'info')
        return response
      })

    try {
      const refreshToken = localStorage.getItem('refreshToken')

      if (refreshToken !== null) {
        const response = await refresh(refreshToken)

        if (response.status === 401) {
          return redirectToLogin(response)
        }
        localStorage.setItem('token', response.data.accessToken)
        localStorage.setItem('refreshToken', response.data.refreshToken)

        axios.defaults.headers = {
          Authorization: `Bearer ${response.data.accessToken}`,
        }
        window.location.reload()
      }
    } catch (err) {
      return redirectToLogin(err.response)
    }
  } else {
    return Promise.reject(error)
  }
}

export default [UNAUTHORIZED_REDIRECT]
